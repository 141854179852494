import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const WhoWeServe = ({ ioRef }) => {
  const data = useStaticQuery(graphql`
    query strapiQuery {
      strapiWhoWeServe {
        title
        subtitle
        body
      }
    }
  `);
  const { title, subtitle, body } = data.strapiWhoWeServe;

  return (
    <div
      ref={ioRef}
      id="who-we-serve"
      className="pt-20 mx-auto sm:pt-48 pb-40 sm:pb-60 grid grid-cols-1 max-w-lg lg:max-w-4xl lg:grid-cols-2 gap-16 auto-cols-fr"
    >
      <div className="grid grid-cols-1 max-w-md">
        <h2 className="text-peach-300">{title}</h2>
        <h3 className="text-blue-200 text-2xl my-6 leading-7">{subtitle}</h3>
        <div className="prose text-blue-100">
          <ReactMarkdown source={body} />
        </div>
      </div>
      <div className="divide-y-2 divide-blue-600 mt-12 max-w-md">
        <AnchorLink
          to="/#contact-us"
          className="block bg-gradient-to-b from-peach-100 to-peach-300 text-peach-800 mt-2 mb-8 py-4 px-3 -y-1 shadow-md rounded border border-peach-200 w-full active:button-action hover:button-hover text-center"
        >
          Need a quote?
        </AnchorLink>
        <div className="flex pt-8 space-x-2">
          <AnchorLink
            to="/#contact-us"
            className="block flex-grow text-center py-4 px-1 shadow-lg rounded border text-gray-600 border-gray-100 bg-gradient-to-b from-gray-100 to-gray-300 hover:button-hover active:button-action"
          >
            Life
          </AnchorLink>
          <AnchorLink
            to="/#contact-us"
            className="block flex-grow text-center py-4 px-1 shadow-lg rounded border text-gray-600 border-gray-100 bg-gradient-to-b from-gray-100 to-gray-300 hover:button-hover active:button-action"
          >
            Long Term Care
          </AnchorLink>
          <AnchorLink
            to="/#contact-us"
            className="block flex-grow text-center py-4 px-1 shadow-lg rounded border text-gray-600 border-gray-100 bg-gradient-to-b from-gray-100 to-gray-300 hover:button-hover active:button-action"
          >
            Annuity
          </AnchorLink>
          <AnchorLink
            to="/#contact-us"
            className="block flex-grow text-center py-4 px-1 shadow-lg rounded border text-gray-600 border-gray-100 bg-gradient-to-b from-gray-100 to-gray-300 hover:button-hover active:button-action"
          >
            Disability
          </AnchorLink>
        </div>
      </div>
    </div>
  );
};

export default WhoWeServe;

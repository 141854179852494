import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React from 'react';

const Arrow = ({ anchorLinks, inView, upArrow }) => {
  let classes =
    'w-10 my-10 hover:button-hover hover:shadow-none active:button-action active:shadow-none';

  if (upArrow) classes += ' transform rotate-180 hover:rotate-180';
  if ((inView.whoWeServe && upArrow) || (inView.contactUs && !upArrow))
    classes += ' invisible';
  if (inView.whoWeServe) classes += ' animate-pulse';

  let anchorLink = '#';
  if (!!upArrow) {
    if (inView.whoWeAre) {
      anchorLink = anchorLinks.get('whoWeServe');
    } else if (inView.whatWeDo) {
      anchorLink = anchorLinks.get('whoWeAre');
    } else if (inView.whatToExpect) {
      anchorLink = anchorLinks.get('whatWeDo');
    } else if (inView.contactUs) {
      anchorLink = anchorLinks.get('whatToExpect');
    }
  } else {
    if (inView.whoWeServe) {
      anchorLink = anchorLinks.get('whoWeAre');
    } else if (inView.whoWeAre) {
      anchorLink = anchorLinks.get('whatWeDo');
    } else if (inView.whatWeDo) {
      anchorLink = anchorLinks.get('whatToExpect');
    } else if (inView.whatToExpect) {
      anchorLink = anchorLinks.get('contactUs');
    }
  }
  return (
    <AnchorLink to={anchorLink}>
      <svg
        className={classes}
        style={{ marginLeft: '7px' }}
        width="49"
        height="14"
        viewBox="0 0 49 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M48 2L24.5 12L1 2" stroke="#F59600" strokeWidth="3" />
      </svg>
    </AnchorLink>
  );
};

export default Arrow;

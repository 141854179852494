import React from 'react';
import { useInView } from 'react-intersection-observer';

import LeftNav from '../components/left-nav';
import Layout from '../components/layout';
import SEO from '../components/seo';
import WhoWeServe from '../components/who-we-serve';
import WhoWeAre from '../components/who-we-are';
import WhatWeDo from '../components/what-we-do';
import WhatToExpect from '../components/what-to-expect';
import ContactUs from '../components/contact-us';

const IndexPage = () => {
  const useInViewOptions = {
    threshold: 0.6,
  };
  const refs = {};
  const inViews = {};
  [refs.whoWeServe, inViews.whoWeServe] = useInView(useInViewOptions);
  [refs.whoWeAre, inViews.whoWeAre] = useInView(useInViewOptions);
  [refs.whatWeDo, inViews.whatWeDo] = useInView(useInViewOptions);
  [refs.whatToExpect, inViews.whatToExpect] = useInView(useInViewOptions);
  [refs.contactUs, inViews.contactUs] = useInView(useInViewOptions);

  return (
    <Layout>
      <SEO title="Peach Insurance Services LLC." />
      <LeftNav inView={inViews} />
      <div className="angled-bottom px-8 sm:px-0 sm:pl-16 xl:pl-44 short:pl-0 pt-16">
        <WhoWeServe ioRef={refs.whoWeServe} />
      </div>
      <div className="px-8 sm:px-0 sm:pl-16 xl:pl-44 short:pl-0">
        <WhoWeAre ioRef={refs.whoWeAre} />
      </div>
      <div className="angled-top-bottom px-8 sm:px-0 sm:pl-16 xl:pl-44 short:pl-0">
        <WhatWeDo ioRef={refs.whatWeDo} />
      </div>
      <div className="px-8 sm:px-0 sm:pl-16 xl:pl-44 short:pl-0">
        <WhatToExpect ioRef={refs.whatToExpect} />
      </div>
      <div className="angled-top px-8 sm:px-0 sm:pl-16 xl:pl-44 short:pl-0">
        <ContactUs ioRef={refs.contactUs} />
      </div>
    </Layout>
  );
};

export default IndexPage;

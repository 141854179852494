import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ServiceCategory from './service-category';
const WhatWeDo = ({ ioRef }) => {
  const data = useStaticQuery(graphql`
    query {
      strapiWhatWeDo {
        title
        subtitle
      }
      allStrapiServiceCategory(sort: { order: ASC, fields: id }) {
        nodes {
          title
          description
          services {
            title
            description
            icon {
              publicURL
            }
          }
        }
      }
    }
  `);
  const { title, subtitle } = data.strapiWhatWeDo;

  return (
    <div
      ref={ioRef}
      id="what-we-do"
      className="pt-24 pb-16 sm:pb-40 max-w-lg lg:max-w-4xl mx-auto"
    >
      <h2 className="text-peach-300">{title}</h2>
      <h3 className="text-blue-50 mt-6 mb-10 max-w-xl">{subtitle}</h3>
      {data.allStrapiServiceCategory.nodes.map(
        ({ title, description, services }, index) => (
          <div className="flex">
            <div className="hidden lg:block bg-blue-800 w-1"></div>
            <div className="hidden bg-blue-800 lg:flex justify-center place-items-center rounded-full z-10 w-8 h-8 mt-28 services-hack text-blue-100 text-center">
              <span className="text-blue-100">{index + 1}</span>
            </div>
            <ServiceCategory
              title={title}
              description={description}
              services={services}
            />
          </div>
        )
      )}
    </div>
  );
};

export default WhatWeDo;

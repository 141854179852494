import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import AddressIcon from '../assets/address-icon.svg';
import FaxIcon from '../assets/fax-icon.svg';
import FbIcon from '../assets/fb-icon.svg';
import LinkedinIcon from '../assets/linkedin-icon.svg';
import MailIcon from '../assets/mail-icon.svg';
import PhoneIcon from '../assets/phone-icon.svg';
import Logo from '../assets/logo.svg';
import ContactForm from './contact-form';

const ContactUs = ({ ioRef }) => {
  const data = useStaticQuery(graphql`
    query {
      strapiContactUs {
        title
        subtitle
        leftTitle
        leftSubtitle
        phoneDisplay
        phoneLink
        faxDisplay
        faxLink
        email
        address
        linkedinUrl
        facebookUrl
        disclaimer
      }
    }
  `);

  const {
    title,
    subtitle,
    leftTitle,
    leftSubtitle,
    phoneDisplay,
    phoneLink,
    faxDisplay,
    faxLink,
    email,
    address,
    linkedinUrl,
    facebookUrl,
    disclaimer,
  } = data.strapiContactUs;

  return (
    <div
      ref={ioRef}
      id="contact-us"
      className="py-16 text-center max-w-lg lg:max-w-4xl mx-auto"
    >
      <h2 className="text-peach-300">{title}</h2>
      <h4 className="text-blue-100 my-5">{subtitle}</h4>
      <div className="grid lg:grid-cols-5 shadow-2xl bg-blue-50 rounded mt-12 text-left">
        <div className="bg-blue-600 lg:col-span-2 m-1 rounded grid lg:gap-24 p-8">
          <div>
            <h3 className="text-blue-100 mb-6 text-xl">{leftTitle}</h3>
            <h5 className="text-blue-100 mb-4 hidden lg:block">
              {leftSubtitle}
            </h5>
          </div>
          <ul className="font-compact font-light mb-16 text-xl text-peach-300">
            <li>
              <a href={'tel:' + phoneLink}>
                <PhoneIcon className="inline h-4 mr-3 -mt-px stroke-current text-blue-100" />
                {phoneDisplay}
              </a>
            </li>
            <li className="my-3">
              <a href={'fax:' + faxLink}>
                <FaxIcon className="inline mr-3 h-4 -mt-px fill-current text-blue-100" />
                {faxDisplay}
              </a>
            </li>
            <li>
              <a href={'mailto:' + email}>
                <MailIcon className="inline mr-3 h-4 -mt-px fill-current text-blue-100" />
                {email}
              </a>
            </li>
            <li className="mt-3 grid grid-flow-col w-64">
              <AddressIcon className="inline mr-3 h-4 mt-2 fill-current text-blue-100" />
              {address}
            </li>
          </ul>
          <div className="relative">
            <a href={linkedinUrl}>
              <LinkedinIcon className="inline fill-current text-blue-100" />
            </a>
            <a href={facebookUrl}>
              <FbIcon className="inline mx-6 fill-current text-blue-100" />
            </a>
            <Logo className="absolute right-0 bottom-0 fill-current text-blue-100" />
          </div>
        </div>
        <div className="lg:col-span-3 p-12">
          <ContactForm />
        </div>
      </div>
      <footer className="pt-24 text-blue-500">
        <small>{disclaimer}</small>
      </footer>
    </div>
  );
};

export default ContactUs;

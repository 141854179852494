import React from 'react';

const LogoWithText = () => (
  <form
    className="font-compact"
    method="POST"
    name="contact"
    action="https://formspree.io/f/xjvjbebw"
  >
    <input type="text" name="_gotcha" className="hidden" />
    <input type="hidden" name="form-name" value="contact" />
    <div className="flex flex-wrap -mx-3 mb-6">
      <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
        <label
          className="block uppercase tracking-widest text-gray-400 text-xs mb-2"
          htmlFor="first-name"
        >
          First Name
        </label>

        <input
          className="appearance-none block w-full bg-gray-200 text-gray-600 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-300 placeholder-gray-500"
          id="first-name"
          type="text"
          placeholder="Jane"
          name="first-name"
        />
      </div>
      <div className="w-full md:w-1/2 px-3">
        <label
          className="block uppercase tracking-wide text-gray-400 text-xs mb-2"
          htmlFor="last-name"
        >
          Last Name
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-600 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-300 placeholder-gray-500"
          id="last-name"
          type="text"
          placeholder="Doe"
          name="last-name"
        />
      </div>
    </div>
    <div className="flex flex-wrap -mx-3 mb-6">
      <div className="w-full px-3">
        <label
          className="block uppercase tracking-wide text-gray-400 text-xs mb-2"
          htmlFor="email"
        >
          Email
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-600 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-300 placeholder-gray-500"
          id="email"
          type="email"
          placeholder="janedoe@mail.com"
          name="email"
          required="required"
        />
      </div>
    </div>
    <div className="flex flex-wrap -mx-3 mb-6">
      <div className="w-full px-3">
        <label
          className="block uppercase tracking-wide text-gray-400 text-xs mb-2"
          htmlFor="phone"
        >
          Phone
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-600 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-300 placeholder-gray-500"
          id="phone"
          type="tel"
          placeholder="(123) 456-7890"
          name="phone"
        />
      </div>
    </div>
    <div className="flex flex-wrap -mx-3 mb-6">
      <div className="w-full px-3">
        <label
          className="block uppercase tracking-wide text-gray-400 text-xs mb-2"
          htmlFor="message"
        >
          Message
        </label>
        <textarea
          className="appearance-none block w-full bg-gray-200 text-gray-600 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-300 placeholder-gray-500"
          id="message"
          placeholder="Tell us how we can help you"
          name="message"
        />
      </div>
    </div>
    <div className="flex flex-wrap -mx-3">
      <div className="w-full px-3">
        <button
          type="submit"
          className="mt-2 py-4 px-3 shadow-lg rounded bg-gradient-to-b from-peach-200 to-peach-300 text-peach-900 border border-peach-200 w-full active:button-action hover:button-hover"
        >
          Send Message
        </button>
      </div>
    </div>
  </form>
);

export default LogoWithText;

import React from 'react';
import ClassNames from 'classnames';
import Img from 'gatsby-image';

const Banner = ({ className, logoStyling, color, title, body, Logo }) => {
  const isBlue = color === 'blue' ? true : false;
  const isPeach = color === 'peach' ? true : false;
  const isGray = color === 'gray' ? true : false;

  const wrapperClasses = ClassNames(
    'p-1',
    'sm:p-2',
    'px-3',
    'sm:px-6',
    'self-center', //prob pass this in as it wont always be used
    'shadow-lg',
    'rounded-md',
    'border',
    'bg-gradient-to-b',
    'flex',
    'flex-col',
    {
      'border-blue-200': isBlue,
      'from-blue-100': isBlue,
      'to-blue-200': isBlue,
    },
    {
      'border-peach-200': isPeach,
      'from-peach-100': isPeach,
      'to-peach-200': isPeach,
    },
    {
      'border-gray-200': isGray,
      'from-gray-100': isGray,
      'to-gray-300': isGray,
    },
    className
  );

  const bodyClasses = ClassNames(
    'pb-2',
    'font-compact',
    'text-sm',
    'leading-6',
    'font-light',
    {
      'text-blue-600': isBlue,
      'text-peach-800': isPeach,
      'text-gray-700': isGray,
    }
  );

  const logoClasses = ClassNames('mt-6', 'mb-2', 'self-center', logoStyling);

  const headerClasses = ClassNames('py-2', 'font-serif', {
    'text-peach-500': isPeach,
    'text-blue-400': isBlue,
    'text-gray-500': isGray,
  });

  return (
    <div className={wrapperClasses}>
      <Img fixed={Logo} className={logoClasses} />
      <h4 className={headerClasses}>{title}</h4>
      <p className={bodyClasses}>{body}</p>
    </div>
  );
};

export default Banner;

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Banner from './banner.js';
import ReactMarkdown from 'react-markdown';

const WhatToExpect = ({ ioRef }) => {
  const data = useStaticQuery(graphql`
    query {
      strapiWhatToExpect {
        title
        subtitle
        body
        bannerTitle1
        bannerTitle2
        bannerTitle3
        bannerBody1
        bannerBody2
        bannerBody3
      }
      businessTime: file(relativePath: { eq: "business-time.png" }) {
        childImageSharp {
          fixed(quality: 90, width: 35) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      mobileFriendly: file(relativePath: { eq: "mobile-friendly.png" }) {
        childImageSharp {
          fixed(quality: 90, width: 23) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      star: file(relativePath: { eq: "star.png" }) {
        childImageSharp {
          fixed(quality: 90, width: 35) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const {
    title,
    subtitle,
    body,
    bannerTitle1,
    bannerTitle2,
    bannerTitle3,
    bannerBody1,
    bannerBody2,
    bannerBody3,
  } = data.strapiWhatToExpect;

  return (
    <div
      ref={ioRef}
      id="what-to-expect"
      className="pt-20 sm:pt-24 pb-20 grid grid-rows-3 lg:grid-rows-2 grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8 max-w-lg lg:max-w-4xl mx-auto"
    >
      <div className="row-span-2 col-span-2 pb-4 lg:pb-0 lg:pr-2">
        <h2 className="text-peach-600">{title}</h2>
        <h3 className="text-blue-700 text-xl my-6">{subtitle}</h3>
        <div className="prose text-blue-700">
          <ReactMarkdown>{body}</ReactMarkdown>
        </div>
      </div>
      <Banner
        color="blue"
        title={bannerTitle1}
        body={bannerBody1}
        Logo={data.star.childImageSharp.fixed}
        logoStyling="mr-0.5"
      />
      <Banner
        color="peach"
        title={bannerTitle2}
        body={bannerBody2}
        Logo={data.businessTime.childImageSharp.fixed}
        className="row-span-2"
      />
      <Banner
        color="gray"
        title={bannerTitle3}
        body={bannerBody3}
        Logo={data.mobileFriendly.childImageSharp.fixed}
        logoStyling="mr-1.5"
      />
    </div>
  );
};

export default WhatToExpect;

import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React from 'react';
import Arrow from './arrow';
import NavDecoration from './nav-decoration';

const LeftNav = ({ inView }) => {
  const classesInView = 'text-peach-500';
  const classesOutView = '';

  const divClasses = {
    whoWeServe: inView.whoWeServe ? classesInView : classesOutView,
    whoWeAre: inView.whoWeAre ? classesInView : classesOutView,
    whatWeDo: inView.whatWeDo ? classesInView : classesOutView,
    whatToExpect: inView.whatToExpect ? classesInView : classesOutView,
    contactUs: inView.contactUs ? classesInView : classesOutView,
  };

  const anchorLinks = new Map([
    ['whoWeServe', '/#who-we-serve'],
    ['whoWeAre', '/#who-we-are'],
    ['whatWeDo', '/#what-we-do'],
    ['whatToExpect', '/#what-to-expect'],
    ['contactUs', '/#contact-us'],
  ]);

  return (
    <nav className="hidden sm:block short:hidden fixed z-10 top-1/8 overflow-visible text-peach-600 text-xs font-light font-compact">
      <Arrow anchorLinks={anchorLinks} inView={inView} upArrow />
      <div
        className={
          divClasses.whoWeServe + ' vertical-line pl-6 hover:text-peach-300'
        }
      >
        <AnchorLink to={anchorLinks.get('whoWeServe')}>
          <NavDecoration active={inView.whoWeServe} />
          <span className="hidden xl:inline">Who We Serve</span>
        </AnchorLink>
      </div>
      <div
        className={
          divClasses.whoWeAre + ' vertical-line pl-6 hover:text-peach-300'
        }
      >
        <AnchorLink to={anchorLinks.get('whoWeAre')}>
          <NavDecoration active={inView.whoWeAre} />
          <span className="hidden xl:inline">Who We Are</span>
        </AnchorLink>
      </div>
      <div
        className={
          divClasses.whatWeDo + ' vertical-line pl-6 hover:text-peach-300'
        }
      >
        <AnchorLink to={anchorLinks.get('whatWeDo')}>
          <NavDecoration active={inView.whatWeDo} />
          <span className="hidden xl:inline">What We Do</span>
        </AnchorLink>
      </div>
      <div
        className={
          divClasses.whatToExpect + ' vertical-line pl-6 hover:text-peach-300'
        }
      >
        <AnchorLink to={anchorLinks.get('whatToExpect')}>
          <NavDecoration active={inView.whatToExpect} />
          <span className="hidden xl:inline">What to Expect</span>
        </AnchorLink>
      </div>
      <div className={divClasses.contactUs + ' pl-6 hover:text-peach-300'}>
        <AnchorLink to={anchorLinks.get('contactUs')}>
          <NavDecoration active={inView.contactUs} />
          <span className="hidden xl:inline">Contact Us</span>
        </AnchorLink>
      </div>
      <Arrow anchorLinks={anchorLinks} inView={inView} />
    </nav>
  );
};

export default LeftNav;

import React from "react"
import SelectedDisc from '../assets/selected-disc.svg';

const NavDecoration = ({active}) => {
  if(active) {
    return (
      <SelectedDisc className="inline mr-3 mb-1" />
    )
  } else {
    return (
      <span className="pr-4">&#9675;</span>
    )
  }
}


export default NavDecoration 

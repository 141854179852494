import React from 'react';

const ServiceCard = ({ title, description, icon }) => {
  return (
    <div className="max-w-xs col-span-4 shadow-inner bg-blue-800 rounded-md border-b-2 border-blue-600 hover:button-hover hover:shadow-lg hover:border-transparent">
      <div className="box-border flex flex-col p-4 pt-6 mr-4 leading-6 items-start">
        <img src={icon?.publicURL} className="h-10 ml-px" />
        <div className="mt-4">
          <span className="box-border text-peach-300">{title}</span>
          <p className="mx-0 mt-2 mb-0 text-blue-50 font-compact text-sm">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;

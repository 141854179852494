import React from 'react';
import ServiceCard from './service-card';

const ServiceCategory = ({ title, description, services }) => {
  return (
    <section class="w-full mt-16 lg:mt-24 lg:ml-6">
      <div class="flex flex-col max-w-6xl mx-auto leading-6 border-solid items-stretch justify-center">
        <h2 class="text-2xl text-peach-300">{title}</h2>
        <p class="max-w-2xl mt-2 mb-0  text-blue-50">{description}</p>
        <div class="grid grid-cols-3 gap-5 mt-6 sm:grid-cols-8 lg:grid-cols-12">
          {services.map(({ title, description, icon }) => (
            <ServiceCard title={title} description={description} icon={icon} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceCategory;

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';

const WhoWeAre = ({ ioRef }) => {
  const data = useStaticQuery(graphql`
    {
      strapiWhoWeAre {
        body
        title
        peachieBody
        peachiePhoto {
          childImageSharp {
            fixed(quality: 90, height: 230) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        peachiePhotoCaption
      }
    }
  `);

  const {
    body,
    title,
    peachieBody,
    peachiePhoto,
    peachiePhotoCaption,
  } = data.strapiWhoWeAre;

  return (
    <div
      ref={ioRef}
      className="mx-auto grid lg:grid-cols-2 gap-8 pb-16 max-w-lg lg:max-w-4xl"
    >
      <div>
        <Link to="/peachie">
          <figure
            id="who-we-are"
            className="-mt-16 mx-auto w-1/2 font-compact text-sm text-blue-600 text-center"
          >
            <Img
              className="border-4 border-blue-50 rounded hover:button-hover active:button-action"
              fixed={peachiePhoto.childImageSharp.fixed}
              alt="Portrait of Peachie Thompson"
            />
            <figcaption>{peachiePhotoCaption}</figcaption>
          </figure>
        </Link>
        <div className="prose pt-6 text-blue-800 mx-auto">
          <ReactMarkdown>{peachieBody}</ReactMarkdown>
          Click{' '}
          <Link to="/peachie">
            <span className="text-peach-500 underline">here</span>
          </Link>{' '}
          to learn more about Peachie.
        </div>
      </div>
      <div className="pt-4 lg:pt-16">
        <h2 className="text-peach-500 tracking-widest lg:ml-12 mb-6">
          {title}
        </h2>
        <div className="prose text-gray-800 lg:border-l-2 border-blue-600 lg:pl-12">
          <ReactMarkdown>{body}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
